var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "10" } }, [
            _c("h3", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("settings.project.projectType.themeSetting.title")
                  ) +
                  " - " +
                  _vm._s(
                    _vm.$t(
                      "settings.project.projectType.themeSetting.direct_top_easy.name"
                    )
                  ) +
                  " "
              )
            ]),
            _c("hr")
          ])
        ],
        1
      ),
      _vm._l(_vm.themeFieldConfig, function(themeConfigItem, index) {
        return _c(themeConfigItem.fieldType, {
          key: index,
          tag: "component",
          attrs: {
            handle: themeConfigItem.handle,
            label: themeConfigItem.title,
            desc: themeConfigItem.desc,
            "value-from-db": _vm.themeValues[themeConfigItem.handle]
          },
          on: {
            "update-field": function($event) {
              return _vm.updateSettingValue($event, themeConfigItem.handle)
            }
          }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }