<template>
  <div>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="10">
        <h3>
          {{ $t('settings.project.projectType.themeSetting.title') }}
          -
          {{ $t('settings.project.projectType.themeSetting.direct_top_easy.name') }}
        </h3>
        <hr>
      </b-col>
    </b-row>
    <component
      v-for="(themeConfigItem, index) in themeFieldConfig"
      :key="index"
      :is="themeConfigItem.fieldType"
      :handle="themeConfigItem.handle"
      :label="themeConfigItem.title"
      :desc="themeConfigItem.desc"
      :value-from-db="themeValues[themeConfigItem.handle]"
      @update-field="updateSettingValue($event, themeConfigItem.handle)"
    />
  </div>
</template>

<script>
import ImageUploadRow from '@/components/helper/ImageUploadRow.vue';
import CheckboxRow from '@/components/helper/CheckboxRow.vue';
import InputRow from '@/components/helper/InputRow.vue';

export default {
  name: 'ThemeSettingsDirectTopEasy',
  components: {
    CheckboxRow,
    ImageUploadRow,
    InputRow,
  },
  props: {
    settingValues: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      themeFieldConfig: [
        {
          handle: 'logoimage',
          title: this.$t('settings.project.projectType.themeSetting.direct_top_easy.logoimage.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top_easy.logoimage.desc'),
          fieldType: 'ImageUploadRow',
        },
        {
          handle: 'show_logo',
          title: this.$t('settings.project.projectType.themeSetting.direct_top_easy.show_logo.title'),
          desc: null,
          fieldType: 'CheckboxRow',
        },
        {
          handle: 'pagination',
          title: this.$t('settings.project.projectType.themeSetting.direct_top_easy.pagination.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top_easy.pagination.desc'),
          fieldType: 'CheckboxRow',
        },
        {
          handle: 'navigation',
          title: this.$t('settings.project.projectType.themeSetting.direct_top_easy.navigation.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top_easy.navigation.desc'),
          fieldType: 'CheckboxRow',
        },
        {
          handle: 'navigation_hide',
          title: this.$t('settings.project.projectType.themeSetting.direct_top_easy.navigation_hide.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top_easy.navigation_hide.desc'),
          fieldType: 'CheckboxRow',
        },
        {
          handle: 'navigation_position',
          title: this.$t('settings.project.projectType.themeSetting.direct_top_easy.navigation_position.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top_easy.navigation_position.desc'),
          fieldType: 'CheckboxRow',
        },
        {
          handle: 'contactButtonName',
          title: this.$t('settings.project.projectType.themeSetting.direct_top_easy.contactButtonName.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top_easy.contactButtonName.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'contactButtonLink',
          title: this.$t('settings.project.projectType.themeSetting.direct_top_easy.contactButtonLink.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top_easy.contactButtonLink.desc'),
          fieldType: 'InputRow',
        },
      ],
      themeValues: {
        logoimage: {
          url: null,
          width: null,
          height: null,
          focalpoint: {
            x: 50,
            y: 50,
          },
        },
        show_logo: true,
        navigation: true,
        navigation_hide: false,
        navigation_position: false,
        pagination: false,
        contactButtonName: null,
        contactButtonLink: null,
      },
    };
  },
  created() {
    // add the values to the data
    if (this.settingValues) {
      // eslint-disable-next-line array-callback-return
      Object.entries(this.settingValues).map((itemValue) => {
        const key = itemValue[0];
        const value = itemValue[1];
        if (this.themeValues.hasOwnProperty(key)) {
          this.themeValues[key] = value;
        }
      });
      if (this.themeValues.logoimage.url) {
        if (!this.$checkForCloudImage(this.themeValues.logoimage.url)) {
          this.themeValues.logoimage.url = this.$makeCloudImage(
            this.themeValues.logoimage.url,
            { width: 450, height: 300 },
            this.themeValues.logoimage,
          );
        }
      }
    }
    this.$emit('update-theme-settings', this.themeValues);
  },
  methods: {
    updateSettingValue(value, handle) {
      if (this.themeValues.hasOwnProperty(handle)) {
        this.themeValues[handle] = value;
        this.$emit('update-theme-settings', this.themeValues);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
